import { mapActions, mapGetters } from 'vuex';

export default {
  // data() {
  //   return {
  //     show: false
  //   }
  // },
  computed: {
    ...mapGetters(['featureSubscriptions']),
  },
  methods: {
    ...mapActions(['getAllFeatureSubscriptions']),

    async computeShow(featureName) {
      let show = false;
      if (!featureName || featureName.length == 0) {
        show = true;
      }
      if (!this.featureSubscriptions || !this.featureSubscriptions.length) {
        await this.getAllFeatureSubscriptions();
      }
      this.featureSubscriptions.forEach((item) => {
        if (item.Feature.name === featureName && !item.Feature.suppressed) {
          show = true;
        }
      });

      return show;
    },
  },
};
