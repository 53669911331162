<template>
  <a-spin
    v-if="isScheduling"
    class="d-flex justify-content-center align-items-center loader-overlay"
  ></a-spin>
  <div v-else>
    <a-radio-group
      v-model:value="selectedScheduleType"
      size="small"
      style="width: 400px"
      button-style="solid"
      @change="onTypeChange"
    >
      <a-radio-button
        class="mr-3"
        v-for="type in scheduleTypes"
        :key="type"
        :value="type"
        >{{ type }}</a-radio-button
      >
    </a-radio-group>
    <a-space direction="horizontal" class="w-100 mt-3" style="width: 200px">
      <label>Start Time</label>
      <a-time-picker
        :disabled="!selectedScheduleType"
        v-model:value="startTime"
        value-format="HH:mm:ss"
      />
      <label>End Time</label>
      <a-time-picker
        :disabled="!selectedScheduleType"
        v-model:value="endTime"
        value-format="HH:mm:ss"
      />
    </a-space>
  </div>
</template>

<script>
import { useDeviceSettings } from '../RecordData/composables/useDeviceSettings';
import daysOptions from 'src/config/days-config.js';
import dateHelper from '../../../shared/Helpers/dateHelper';
import { useToast } from 'vue-toastification';

export default {
  name: 'AssignedDevice',
  props: ['showScheduleTaskModal', 'record', 'deviceDetails'],
  emits: ['toggleScheduleTaskModal'],
  setup() {
    const toast = useToast();
    const { isScheduling, updateDeviceSettings } = useDeviceSettings(toast);

    async function handleScheduleForSelectedType() {
      isScheduling.value = true;
      if (!this.nullCheckTimePicker()) {
        isScheduling.value = false;
        return;
      }
      await this.handleScheduleTask();
      // this.$emit('toggleScheduleTaskModal');
      isScheduling.value = false;
      return;
    }

    async function handleScheduleTask() {
      const { id } = this.record;
      const payload = this.getSchedulePayload();
      console.log(id, payload);
      const res = await this.updateDeviceSettings(id, payload);
      if (res === 'error') {
        this.toast.error('Error occured while Scheduling!', { timeout: 2000 });
        return;
      }
      this.toast.success('Your device has been scheduled successfully', {
        timeout: 2000
      });
      this.startTime = this.startTime.split('T')[1];
      this.endTime = this.endTime.split('T')[1];
    }
    return {
      toast,
      isScheduling,
      handleScheduleTask,
      handleScheduleForSelectedType,
      // showSchedule,
      updateDeviceSettings,
      daysOptions
    };
  },
  watch: {
    startEndTime(val) {
      this.startEndTime = val;
    },
    selectedScheduleType(val) {
      if (
        val == 'Video Upload' &&
        this.record.start_recording_time &&
        this.record.end_recording_time
      ) {
        this.startTime = this.record.start_recording_time
          .split('T')[1]
          .replace('Z', '');
        this.endTime = this.record.end_recording_time
          .split('T')[1]
          .replace('Z', '');
      } else if (
        val == 'Analytics Upload' &&
        this.record.analytics_start_time &&
        this.record.analytics_end_time
      ) {
        this.startTime = this.record.analytics_start_time
          .split('T')[1]
          .replace('Z', '');
        this.endTime = this.record.analytics_end_time
          .split('T')[1]
          .replace('Z', '');
      }
    }
  },
  data() {
    return {
      scheduleTypes: ['Video Upload', 'Analytics Upload'],
      selectedScheduleType: null,
      startEndTime: null,
      selectedDays: [],
      startTime: null,
      endTime: null
    };
  },
  created() {
    // this.selectedDays = JSON.parse(this.record?.day_of_week_for_recording);
  },

  methods: {
    showModal() {
      // this.showScheduleModalAnalytics(this.record);
      return this.showScheduleTaskModal;
    },
    closeModal() {
      this.$emit('toggleScheduleTaskModal');
      return;
      // this.showScheduleTaskModal = false;
    },
    nullCheckTimePicker() {
      if (!this.startTime || !this.endTime) {
        this.toast.error('Missing start or end time value');
        return false;
      }
      return true;
    },

    onTimeRangeChange(value) {
      this.startEndTime = value;
    },
    onTypeChange(event) {
      const { value } = event.target;
      this.selectedScheduleType = value;
    },
    getStartEndTime() {
      const [start, end] = this.startEndTime;
      if (!start.$d && !end.$d) {
        this.toast.info('Schedule time is not properly selected!');
        //   isScheduling.value = false;
        return;
      }
      const startDate = dateHelper.getFormattedDate(start.$d);
      const endDate = dateHelper.getFormattedDate(end.$d);
      const [startTime] = new Date(start.$d).toTimeString().split(' ');
      const [endTime] = new Date(end.$d).toTimeString().split(' ');
      return [startDate + 'T' + startTime, endDate + 'T' + endTime];
    },
    onDaysSelect(days) {
      console.log('days', days);
    },
    getFormattedDate(time, date) {
      const dateParts = date.split('/');

      // Extract year, month, and day from the date
      const year = dateParts[2];
      const month = dateParts[1];
      const day = dateParts[0];

      // Split the time part using ':' as the separator
      const timeParts = time.split(':');

      // Extract hour, minute, and second from the time
      const hour = timeParts[0];
      const minute = timeParts[1];
      const second = timeParts[2] || '00'; // If seconds are missing, assume "00"

      // Create the ISO 8601 date-time string
      return `${year}-${day}-${month}T${hour}:${minute}:${second}`;
    },
    getSchedulePayload() {
      // const [start, end] = this.getStartEndTime(this.startEndTime);
      const taskId = this.record.Task;
      const scheduleType = this.selectedScheduleType;

      const schedule = {
        Task: taskId,
        is_shift_managed: false
      };
      const date = new Date();
      const dateStr = date.toLocaleDateString('en-US', {
        format: 'YYYY-MM-DD'
      });

      this.startTime = this.getFormattedDate(this.startTime, dateStr);
      this.endTime = this.getFormattedDate(this.endTime, dateStr);

      if (scheduleType === 'Video Upload') {
        schedule.start_recording_time = this.startTime;
        schedule.end_recording_time = this.endTime;
      } else if (scheduleType === 'Analytics Upload') {
        schedule.analytics_start_time = this.startTime;
        schedule.analytics_end_time = this.endTime;
      }
      // else if (scheduleType === 'Trace') {
      //   schedule.trace_start_time = start;
      //   schedule.trace_end_time = end;
      //   schedule.is_shift_managed = false;
      // }
      // else if (scheduleType === 'Inference') {
      //   schedule.shift_start_time = start;
      //   schedule.shift_end_time = end;
      //   schedule.recordInference = this.deviceDetails.recordInference;
      // }

      return schedule;
    }
  }
};
</script>
